import React, { useEffect } from 'react';
import { Text } from '@consta/uikit/Text';
import Certificates from './Certificates';
import './about.css'

const AboutCompany = () => {
    useEffect(() => {
        const hash = window.location.hash;
        if (hash) {
          const element = document.querySelector(hash);
          if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
          }
        }
      }, []);
    return (
        <div id="about-section" className="custom-div">
            <Text size="2xl" weight="bold" view="primary" style={{marginBottom: 30}}>О Компании</Text>
            <Text size="l" view="secondary" align="left" style={{marginBottom: 30}}>
                Добро пожаловать в Farra-Lab — вашего надежного партнера в области неразрушающего контроля. Мы молодая и динамично развивающаяся лаборатория дефектоскопии, обеспечивающая высококачественные услуги по проверке целостности материалов и конструкций без их повреждения. Наша миссия — гарантировать безопасность и надежность вашей продукции, минимизируя риски и увеличивая срок службы оборудования.
            </Text>
            <Text size="m" view="primary" spacing="m" align="left" style={{marginBottom: 15}}>
                <strong>Наши Преимущества</strong>
            </Text>
            <Text size="m" align="left" style={{marginBottom: 20}}>
                <strong>Квалификация и сертификация:</strong> Наша команда состоит из сертифицированных специалистов, обладающих всеми необходимыми разрешениями и лицензиями для проведения неразрушающего контроля. Мы регулярно проходим обучение и сертификацию, чтобы соответствовать последним стандартам в нашей отрасли.
            </Text>
            <Text size="m" align="left" style={{marginBottom: 20}}>
                <strong>Современное оборудование:</strong> Мы используем только самое современное оборудование, позволяющее выполнять все виды дефектоскопии на высшем уровне. Это включает в себя ультразвуковую, гидравлическую, магнитную и визуально-измерительную дефектоскопию.
            </Text>
            <Text size="m" align="left" style={{marginBottom: 20}}>
                <strong>Широкий спектр услуг:</strong> Мы предлагаем полный спектр услуг по неразрушающему контролю для самых разнообразных отраслей, включая машиностроение, строительство, нефтегазовую и атомную промышленность.
            </Text>
            <Text size="m" view="primary" spacing="m" align="left" style={{marginBottom: 15}}>
                <strong>Наши Обязательства</strong>
            </Text>
            <Text size="m" align="left" style={{marginBottom: 20}}>
                В Farra-Lab мы стремимся к тому, чтобы каждый клиент оставался доволен качеством и результатом нашей работы. Мы гарантируем прозрачность всех процессов и стремимся к постоянному улучшению качества наших услуг.
            </Text>
            <Text size="m" view="primary" spacing="m" align="left" style={{marginBottom: 15}}>
                <strong>Присоединяйтесь к Нам</strong>
            </Text>
            <Text size="m" align="left" style={{marginBottom: 30}}>
                Выбирая Farra-Lab, вы выбираете надежного партнера, который поможет обеспечить долговечность и безопасность вашей продукции. Свяжитесь с нами сегодня, чтобы обсудить, как мы можем помочь вам в достижении ваших производственных целей.
            </Text>
            <Text size="xl" weight="bold" view="primary" style={{marginBottom: 30}}>Разрешительные документы</Text>
            <Certificates/>
            <Text size="xl" view="primary" spacing="m" style={{marginBottom: 20, marginTop: 30}}>
                <strong>Контактная информация</strong>
            </Text>
            <Text size="m" align="left" style={{marginBottom: 10}}>
                <strong>Юридическое наименование:</strong> ИП Фаррахов Рустам Раисович
            </Text>
            <Text size="m" align="left" style={{marginBottom: 10}}>
                <strong>Полное наименование организации:</strong> Индивидуальный предпринематель Фаррахов Рустам Раисович
            </Text>
            <Text size="m" align="left" style={{marginBottom: 10}}>
                <strong>Юридический адрес:</strong> 628680 ХМАО-югра, г. Мегион, ул. Нефтяников д.5 кв.3
            </Text>
            <Text size="m" align="left" style={{marginBottom: 10}}>
                <strong>Фактический адрес:</strong> 450092б г. Уфа, ул. Рабкоров д.10 кв.48
            </Text>
            <Text size="m" align="left" style={{marginBottom: 10}}>
                <strong>ИНН:</strong> 027104023400
            </Text>
            <Text size="m" align="left" style={{marginBottom: 10}}>
                <strong>ОГРНИП:</strong> 322861700066274
            </Text>
            <Text size="m" align="left" style={{marginBottom: 10}}>
                <strong>Контакты:</strong> +7 (922) 785-24-78, +7 (951) 973-16-56, +7 (904) 467-07-98
            </Text>
            <Text size="m" align="left" style={{marginBottom: 20}}>
                <strong>E-mail:</strong> info@farra-lab.ru
            </Text>
            <Text size="xl" view="primary" spacing="m" style={{marginBottom: 20}}>
                <strong>Реквизиты для переводов и договоров</strong>
            </Text>
            <Text size="m" align="left" style={{marginBottom: 10}}>
                <strong>Банк:</strong> ОО "На Мира" Филиал № 6602 Банка ВТБ (ПАО)
            </Text>
            <Text size="m" align="left" style={{marginBottom: 10}}>
                <strong>БИК:</strong> 044525411
            </Text>
            <Text size="m" align="left" style={{marginBottom: 10}}>
                <strong>р/с:</strong> 40802810219350000308
            </Text>
            <Text size="m" align="left" style={{marginBottom: 20}}>
                <strong>к/с:</strong> 30101810145250000411
            </Text>
        </div>
    );
}

export default AboutCompany;