import React, { useState } from 'react';
import { IconBag } from '@consta/icons/IconBag';
import { IconPhone } from '@consta/icons/IconPhone';
import { Chips } from '@consta/uikit/Chips';
import { Modal } from '@consta/uikit/Modal';
import { Text } from '@consta/uikit/Text';
import { useNavigate } from 'react-router-dom';
import './chips.css';

interface MyChipsProps {
  className?: string;
}

export const MyChipsHeader: React.FC<MyChipsProps> = ({ className }) => {
  const navigate = useNavigate();

  const goToAboutCompany = () => {
    navigate('/about-company#about-section');
  };

  return (
    <div>
      <Chips
        className={className}
        items={[
          { iconLeft: IconBag, label: 'О компании', onClick: goToAboutCompany }
        ]}
        interactive
        activeView='secondary'
        onItemClick={(item) => item.onClick()}
        style={{zIndex: 9999}}
      />
    </div>
  );
};

export default MyChipsHeader;