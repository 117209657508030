import React, { useEffect } from 'react';
import { Text } from '@consta/uikit/Text';

const MethodVik = () => {
    useEffect(() => {
        const hash = window.location.hash;
        if (hash) {
          const element = document.querySelector(hash);
          if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
          }
        }
      }, []);
    return (
        <div id="method-section" className="custom-div">
            <Text size="2xl" weight="bold" view="primary" align="left" style={{marginBottom: 30}}>Визуально-измерительный контроль (ВИК)</Text>
            <Text size="m" view="primary" spacing="m" align="left" style={{marginBottom: 15}}>
                <strong>Описание метода:</strong>
            </Text>
            <Text size="m" align="left" style={{marginBottom: 20}}>
                <strong>Визуально-измерительный контроль (ВИК) —</strong> это базовый метод неразрушающего контроля, который включает в себя прямой визуальный осмотр объектов с помощью глаза, а также с использованием различных оптических приборов, таких как увеличительные стекла, микроскопы, видеокамеры и бороскопы. Метод позволяет оценить внешнее состояние объекта, измерить его геометрические размеры и выявить видимые дефекты.
            </Text>
            <Text size="m" view="primary" spacing="m" align="left" style={{marginBottom: 15}}>
                <strong>Применение и возможности:</strong>
            </Text>
            <Text size="m" align="left" style={{marginBottom: 20}}>
                ВИК широко используется во многих отраслях для первичной проверки качества изделий, оценки состояния оборудования и мониторинга процессов изготовления и эксплуатации. Метод идеально подходит для быстрой оценки сварных швов, поверхностей металлоконструкций и составляющих элементов сложных механизмов.
            </Text>
            <Text size="m" view="primary" spacing="m" align="left" style={{marginBottom: 15}}>
                <strong>Оборудование и технологии:</strong>
            </Text>
            <Text size="m" align="left" style={{marginBottom: 20}}>
                Применение современных видеоэндоскопов и цифровых микроскопов с функцией записи и анализа изображений позволяет значительно повысить точность и информативность визуального осмотра. Технологии 3D-сканирования и цифровой фотографии используются для создания точных моделей объектов и последующего детального анализа.
            </Text>
            <Text size="m" view="primary" spacing="m" align="left" style={{marginBottom: 15}}>
                <strong>Примеры работ и кейсы:</strong>
            </Text>
            <Text size="m" align="left" style={{marginBottom: 10}}>
                1. Инспекция сварных соединений на строительной площадке: быстрый осмотр швов на предмет непроваров и других поверхностных дефектов.
            </Text>
            <Text size="m" align="left" style={{marginBottom: 10}}>
                2. Проверка состояния лопастей ветрогенераторов: использование видеоэндоскопа для осмотра труднодоступных участков.
            </Text>
            <Text size="m" align="left" style={{marginBottom: 30}}>
                3. Контроль геометрии деталей нефтегазового оборудования: точные измерения компонентов для обеспечения их соответствия проектным параметрам.
            </Text>
        </div>
    );
}

export default MethodVik;