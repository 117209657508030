import React, { useEffect } from 'react';
import { Text } from '@consta/uikit/Text';

const ObjectSs = () => {
    useEffect(() => {
        const hash = window.location.hash;
        if (hash) {
          const element = document.querySelector(hash);
          if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
          }
        }
      }, []);
    return (
        <div id="object-section" className="custom-div">
            <Text size="2xl" weight="bold" view="primary" align="left" style={{marginBottom: 30}}>Сварные соединения</Text>
            <Text size="m" view="primary" spacing="m" align="left" style={{marginBottom: 15}}>
                <strong>Описание объекта контроля:</strong>
            </Text>
            <Text size="m" align="left" style={{marginBottom: 20}}>
                <strong>Сварные соединения -</strong> это критические элементы конструкций, от качества которых зависят прочность и надежность изделий. Контроль сварных соединений необходим для обнаружения дефектов, таких как трещины, поры, непровары и другие несоответствия, которые могут привести к снижению эксплуатационных характеристик и отказу конструкций.
            </Text>
            <Text size="m" view="primary" spacing="m" align="left" style={{marginBottom: 15}}>
                <strong>Методы контроля:</strong>
            </Text>
            <Text size="m" align="left" style={{marginBottom: 20}}>
                Для контроля сварных соединений применяются различные методы неразрушающего контроля, включая ультразвуковой контроль (УЗК), радиографический контроль (РГК), магнитный контроль (МК) и визуальный контроль. Выбор метода зависит от типа сварного соединения, материала и требований к качеству.
            </Text>
            <Text size="m" view="primary" spacing="m" align="left" style={{marginBottom: 15}}>
                <strong>Примеры работ и кейсы:</strong>
            </Text>
            <Text size="m" align="left" style={{marginBottom: 10}}>
                1. Контроль сварных швов трубопровода: обнаружение трещин и непроваров с помощью УЗК.
            </Text>
            <Text size="m" align="left" style={{marginBottom: 10}}>
                2. Визуальный осмотр сварных швов при строительстве моста: Оценка качества сварки и выявление поверхностных дефектов, таких как непровары и трещины, для обеспечения надежности конструкции.
            </Text>
            <Text size="m" align="left" style={{marginBottom: 20}}>
                3. Проверка сварных швов металлоконструкций: применение МК для обнаружения поверхностных дефектов.
            </Text>
            <Text size="m" view="primary" spacing="m" align="left" style={{marginBottom: 15}}>
                <strong>Особенности и рекомендации:</strong>
            </Text>
            <Text size="m" align="left" style={{marginBottom: 30}}>
                При контроле сварных соединений важно учитывать тип сварки, толщину материала и доступность области шва. Рекомендуется проводить комплексный контроль, сочетая различные методы НК для повышения точности обнаружения дефектов.
            </Text>
        </div>
    );
}

export default ObjectSs;