import React from 'react';
import { Card } from '@consta/uikit/Card';
import { Text } from '@consta/uikit/Text';
import { Picture } from '@consta/uikit/Picture';
import { useNavigate } from 'react-router-dom';
import { Item } from '../../components/types/ItemsLink'; 
import './home.css'

interface ImageBlockProps {
  item: Item;
}

const ImageBlock: React.FC<ImageBlockProps> = ({ item }) => {
    const navigate = useNavigate();
  
    const handleNavigate = () => {
      navigate(item.to);
    };
  
    return (
        <div onClick={handleNavigate} className="image-card">
          <Card verticalSpace="2xl" horizontalSpace="2xl" form="round">
            <div className="image-container">
                <Picture src={item.imageSrc} alt={item.label} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                <Text className="text-overlay" size="m" view="primary" align="center">
                    {item.label}
                </Text>
            </div>
          </Card>
        </div>
      );
  };
  
  export default ImageBlock;