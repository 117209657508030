import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation } from 'swiper/modules';
import { Text } from '@consta/uikit/Text';
import AnimatedImage from '../CompanyLogoAnamation/CompanyLogoAnimation';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/navigation';
import './slider.css';

const Slider = () => {
    return (
        <Swiper
            modules={[Autoplay, Navigation]}
            spaceBetween={50}

            observer={true}  
            observeParents={true}  
            autoplay={{
                delay: 5000,
                disableOnInteraction: false,
            }}
            navigation={true}
            style={{marginTop: 10}}
            breakpoints={{

                600: {
                    slidesPerView: 1, 
                },

                700: {
                    slidesPerView: 2, 
                }
            }}
        >
            <SwiperSlide>
                <div style={{ position: 'relative', width: '100%', height: 'auto', overflow: 'hidden'}}>
                    <img src="/assets/images/defektoskopist.webp" alt="Image 1" style={{ width: '100%', height: 'auto' }} />
                    <Text 
                        className='swiper-slide-text'
                        size="l" 
                        view="primary" 
                        weight="bold">
                        Обеспечьте надёжность – выявляем дефекты до их проявления. Ваши конструкции в надёжных руках.
                    </Text>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div style={{ position: 'relative', height: 'auto' , width: '100%', overflow: 'hidden' }}>
                    <img src="/assets/images/oborudovanie.webp" alt="Image 2" style={{ width: '100%', height: 'auto' }} />
                    <Text 
                        className='swiper-slide-text'
                        size="l" 
                        view="primary" 
                        weight="bold"
                    >
                        Точность диагностики – безопасность вашего проекта. Передовые методы дефектоскопии на страже качества.
                    </Text>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div style={{ position: 'relative', width: '100%', height: 'auto', overflow: 'hidden' }}>
                    <img src="/assets/images/defektoskopiya-trub.webp" alt="Image 3" style={{ width: '100%', height: 'auto' }} />
                    <Text 
                        className='swiper-slide-text'
                        size="l" 
                        view="primary" 
                        weight="bold">
                        Сократите риски и издержки – доверьтесь экспертам в области дефектоскопии. Защитите свой проект от непредвиденных проблем.
                    </Text>
                </div>
            </SwiperSlide>
            <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '100%', height: '100%', pointerEvents: 'none', zIndex: 1 }}>
                <AnimatedImage />
            </div>
        </Swiper> 
    );
};

export default Slider;