import React, { useEffect } from 'react';
import { Text } from '@consta/uikit/Text';

const MethodMk = () => {
    useEffect(() => {
        const hash = window.location.hash;
        if (hash) {
          const element = document.querySelector(hash);
          if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
          }
        }
      }, []);
    return (
        <div id="method-section" className="custom-div">
            <Text size="2xl" weight="bold" view="primary" align="left" style={{marginBottom: 30}}>Магнитный контроль (МК)</Text>
            <Text size="m" view="primary" spacing="m" align="left" style={{marginBottom: 15}}>
                <strong>Описание метода:</strong>
            </Text>
            <Text size="m" align="left" style={{marginBottom: 20}}>
                <strong>Магнитный контроль —</strong> это метод неразрушающего контроля, используемый для обнаружения поверхностных и подповерхностных дефектов в ферромагнитных материалах. Метод основан на намагничивании объекта и анализе распределения магнитного поля вокруг него. Дефекты в структуре материала вызывают локальные искажения магнитного поля, которые можно обнаружить с помощью специальных датчиков или визуально при использовании магнитных частиц.
            </Text>
            <Text size="m" view="primary" spacing="m" align="left" style={{marginBottom: 15}}>
                <strong>Применение и возможности:</strong>
            </Text>
            <Text size="m" align="left" style={{marginBottom: 20}}>
                Магнитный контроль применяется для проверки сварных соединений, металлоконструкций, шасси автомобилей, железнодорожных рельсов и других ферромагнитных конструкций. Метод эффективен для обнаружения трещин, закалочных трещин, пор и других дефектов, которые могут повлиять на прочность и надежность металлических изделий.
            </Text>
            <Text size="m" view="primary" spacing="m" align="left" style={{marginBottom: 15}}>
                <strong>Оборудование и технологии:</strong>
            </Text>
            <Text size="m" align="left" style={{marginBottom: 20}}>
                Для проведения магнитного контроля используются магнитные дефектоскопы, портативные магнитизирующие устройства, а также магнитные порошки и жидкости, содержащие частицы, видимые в ультрафиолете. Современные технологии позволяют автоматизировать процесс и повысить точность обнаружения дефектов.
            </Text>
            <Text size="m" view="primary" spacing="m" align="left" style={{marginBottom: 15}}>
                <strong>Примеры работ и кейсы:</strong>
            </Text>
            <Text size="m" align="left" style={{marginBottom: 10}}>
                1. Контроль сварных швов в мостостроении: выявление трещин на ранних стадиях, что предотвращает дальнейшее развитие дефектов.
            </Text>
            <Text size="m" align="left" style={{marginBottom: 10}}>
                2. Проверка рельсов железной дороги: обнаружение подповерхностных трещин, которые невозможно выявить визуальным осмотром.
            </Text>
            <Text size="m" align="left" style={{marginBottom: 30}}>
                3. Измерение потери площади сечения стальных канатов и поиск локальных дефектов
            </Text>
        </div>
    );
}

export default MethodMk;