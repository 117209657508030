import { presetGpnDefault, presetGpnDark, presetGpnDisplay, ThemePreset } from '@consta/uikit/Theme';
import { IconSun } from '@consta/icons/IconSun';
import { IconMoon } from '@consta/icons/IconMoon';
import { IconComponent } from '@consta/icons/Icon';

export interface ThemeItem {
  key: string;
  label: string;
  icon: IconComponent<"span">; // Предполагается, что у вас есть тип IconComponent
  theme: ThemePreset;
}

export const items: ThemeItem[] = [
  {
    key: 'Default',
    label: 'Default',
    icon: IconSun,
    theme: presetGpnDefault,
  },
  {
    key: 'Dark',
    label: 'Dark',
    icon: IconMoon,
    theme: presetGpnDark,
  },
  /*{
    key: 'Display',
    label: 'Display',
    icon: IconLightningBolt,
    theme: presetGpnDisplay,
  },*/
];

const defaultIcon = IconSun;
const defaultTheme = presetGpnDefault; // или другая стандартная тема

export const getItemIcon = (item: string) => items.find((themeItem) => themeItem.key === item)?.icon || defaultIcon;
export const getTheme = (item: string) => items.find((themeItem) => themeItem.key === item)?.theme || defaultTheme;