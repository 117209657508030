import React, { useEffect } from 'react';
import { Text } from '@consta/uikit/Text';

const MethodGi = () => {
    useEffect(() => {
        const hash = window.location.hash;
        if (hash) {
          const element = document.querySelector(hash);
          if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
          }
        }
      }, []);
    return (
        <div id="method-section" className="custom-div">
            <Text size="2xl" weight="bold" view="primary" align="left" style={{marginBottom: 30}}>Гидравлическое испытание (ГИ)</Text>
            <Text size="m" view="primary" spacing="m" align="left" style={{marginBottom: 15}}>
                <strong>Описание метода:</strong>
            </Text>
            <Text size="m" align="left" style={{marginBottom: 20}}>
                <strong>Гидравлическое испытание -</strong> это метод проверки герметичности и прочности сосудов, трубопроводов и других емкостей под давлением. Метод заключается в заполнении контролируемого объекта водой или другой несжимаемой жидкостью под высоким давлением, которое обычно значительно превышает рабочее давление.
            </Text>
            <Text size="m" view="primary" spacing="m" align="left" style={{marginBottom: 15}}>
                <strong>Применение и возможности:</strong>
            </Text>
            <Text size="m" align="left" style={{marginBottom: 20}}>
                Гидравлическое испытание применяется для сертификации новых изделий, а также для периодической проверки состояния уже эксплуатируемого оборудования. Этот метод позволяет обнаружить дефекты, такие как микротрещины, несплошности материала и слабые места в конструкции, которые могут быть невидимы при других видах контроля.
            </Text>
            <Text size="m" view="primary" spacing="m" align="left" style={{marginBottom: 15}}>
                <strong>Оборудование и технологии:</strong>
            </Text>
            <Text size="m" align="left" style={{marginBottom: 20}}>
                Для проведения гидравлического испытания используются специализированные насосы и манометры для создания и контроля давления, а также системы улавливания утечек для обнаружения дефектов. Современное оборудование позволяет автоматизировать процесс и повысить его безопасность и эффективность.
            </Text>
            <Text size="m" view="primary" spacing="m" align="left" style={{marginBottom: 15}}>
                <strong>Примеры работ и кейсы:</strong>
            </Text>
            <Text size="m" align="left" style={{marginBottom: 10}}>
                1. Испытание новых трубопроводов на нефтеперерабатывающем заводе: обнаружение утечек и подтверждение прочности конструкции.
            </Text>
            <Text size="m" align="left" style={{marginBottom: 10}}>
                2. Периодическая проверка сосудов под давлением в химической промышленности: выявление слабых мест после длительной эксплуатации.
            </Text>
            <Text size="m" align="left" style={{marginBottom: 30}}>
                3. Сертификация новых моделей газовых баллонов: гарантия безопасности и соответствие стандартам.
            </Text>
        </div>
    );
}

export default MethodGi;