import React, { useEffect } from 'react';
import { Text } from '@consta/uikit/Text';

const ObjectNo = () => {
    useEffect(() => {
        const hash = window.location.hash;
        if (hash) {
          const element = document.querySelector(hash);
          if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
          }
        }
      }, []);
    return (
        <div id="object-section" className="custom-div">
            <Text size="2xl" weight="bold" view="primary" align="left" style={{marginBottom: 30}}>Нефтегазовое оборудование</Text>
            <Text size="m" view="primary"  spacing="m" align="left" style={{marginBottom: 15}}>
                <strong>Описание объекта контроля:</strong>
            </Text>
            <Text size="m" align="left" style={{marginBottom: 20}}>
                <strong>Нефтегазовое оборудование</strong>, включая насосы, клапаны, трубопроводы и хранилища, является жизненно важной частью добычи и переработки нефти и газа. Это оборудование подвергается экстремальным условиям эксплуатации, таким как высокое давление, агрессивные химические воздействия и механическое изнашивание, что делает его регулярный контроль необходимым для предотвращения аварий и обеспечения безопасности процессов.
            </Text>
            <Text size="m" view="primary" spacing="m" align="left" style={{marginBottom: 15}}>
                <strong>Методы контроля:</strong>
            </Text>
            <Text size="m" align="left" style={{marginBottom: 20}}>
                Для контроля нефтегазового оборудования широко используется ультразвуковой контроль (УЗК), который позволяет обнаруживать внутренние дефекты, такие как коррозия и трещины в металле. Магнитный контроль (МК) применяется для выявления поверхностных и подповерхностных дефектов в ферромагнитных материалах. Визуально-измерительный контроль (ВИК) также активно используется для оценки общего состояния оборудования и проверки его геометрических параметров.
            </Text>
            <Text size="m" view="primary" spacing="m" align="left" style={{marginBottom: 15}}>
                <strong>Примеры работ и кейсы:</strong>
            </Text>
            <Text size="m" align="left" style={{marginBottom: 10}}>
                1. Ультразвуковое исследование трубопроводов на предмет коррозии: выявление утонения стенок и риск потенциальных утечек.
            </Text>
            <Text size="m" align="left" style={{marginBottom: 10}}>
                2. Магнитный контроль сварных соединений на морских платформах: обнаружение трещин и других дефектов, вызванных морской средой.
            </Text>
            <Text size="m" align="left" style={{marginBottom: 20}}>
                3. Визуальный осмотр и измерение толщины стенок резервуаров для хранения нефти: оценка износа и принятие мер по его устранению.
            </Text>
            <Text size="m" view="primary" spacing="m" align="left" style={{marginBottom: 15}}>
                <strong>Особенности и рекомендации:</strong>
            </Text>
            <Text size="m" align="left" style={{marginBottom: 30}}>
            При контроле нефтегазового оборудования важно регулярно проводить тщательные инспекции, учитывая, что даже малейшие дефекты могут привести к серьезным последствиям. Использование современных методов неразрушающего контроля позволяет своевременно выявлять проблемы и предотвращать аварийные ситуации, обеспечивая тем самым безопасность и надежность эксплуатации оборудования.
            </Text>
        </div>
    );
}

export default ObjectNo;