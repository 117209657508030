import React, { useEffect } from 'react';
import { Text } from '@consta/uikit/Text';

const MethodUzk = () => {
    useEffect(() => {
        const hash = window.location.hash;
        if (hash) {
          const element = document.querySelector(hash);
          if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
          }
        }
      }, []);
    return (
        <div id="method-section" className="custom-div">
            <Text size="2xl" weight="bold" view="primary" align="left" style={{marginBottom: 30}}>Ультразвуковой контроль (УЗК)</Text>
            <Text size="m" view="primary" spacing="m" align="left" style={{marginBottom: 15}}>
                <strong>Описание метода:</strong>
            </Text>
            <Text size="m" align="left" style={{marginBottom: 20}}>
                <strong>Ультразвуковой контроль (УЗК) -</strong> это метод неразрушающего контроля, который использует ультразвуковые волны для обнаружения внутренних дефектов в материалах, таких как трещины, поры, непровары и другие неоднородности. Метод основан на принципе отражения и преломления ультразвуковых волн при прохождении через границы раздела сред с различными акустическими свойствами.
            </Text>
            <Text size="m" view="primary" spacing="m" align="left" style={{marginBottom: 15}}>
                <strong>Применение и возможности:</strong>
            </Text>
            <Text size="m" align="left" style={{marginBottom: 20}}>
                УЗК широко применяется в различных отраслях промышленности для контроля качества сварных соединений, трубопроводов, металлоконструкций, деталей машин и оборудования. Метод позволяет обнаруживать дефекты на различных стадиях производства и эксплуатации, а также проводить толщинометрию для измерения толщины стенок и обнаружения коррозии.
            </Text>
            <Text size="m" view="primary" spacing="m" align="left" style={{marginBottom: 15}}>
                <strong>Оборудование и технологии:</strong>
            </Text>
            <Text size="m" align="left" style={{marginBottom: 20}}>
                Для проведения УЗК используются ультразвуковые дефектоскопы, преобразователи и сканеры. Современные технологии позволяют проводить автоматизированный и фазированный ультразвуковой контроль, обеспечивая высокую точность и скорость обследования.
            </Text>
            <Text size="m" view="primary" spacing="m" align="left" style={{marginBottom: 15}}>
                <strong>Примеры работ и кейсы:</strong>
            </Text>
            <Text size="m" align="left" style={{marginBottom: 10}}>
                1. Контроль сварных швов трубопровода: обнаружение непроваров и микротрещин в сварных соединениях.
            </Text>
            <Text size="m" align="left" style={{marginBottom: 10}}>
                2. Толщинометрия корпуса сосуда: измерение толщины стенок и выявление участков с коррозией.
            </Text>
            <Text size="m" align="left" style={{marginBottom: 30}}>
                3. Обследование лопаток турбины: выявление трещин и дефектов в материале лопаток.
            </Text>
        </div>
    );
}

export default MethodUzk;