import React, { useEffect, useState } from 'react';
import './companyLogoAnamation.css';

function AnimatedImage() {
    const [startAnimation, setStartAnimation] = useState(false);

    useEffect(() => {
        setStartAnimation(true);  // Начать анимацию при монтировании компонента
    }, []);

    // Добавление класса 'active' для активации анимации
    return (
        <div className={startAnimation ? 'animated-slide-in active' : 'animated-slide-in'}>
            <img src="/assets/animations/cosmic_white.png" alt="Animated"  className='img-anim'/>
        </div>
    );
}

export default AnimatedImage;