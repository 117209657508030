import React, { useEffect } from 'react';
import { Text } from '@consta/uikit/Text';

const ObjectSk = () => {
    useEffect(() => {
        const hash = window.location.hash;
        if (hash) {
          const element = document.querySelector(hash);
          if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
          }
        }
      }, []);
    return (
        <div id="object-section" className="custom-div">
            <Text size="2xl" weight="bold" view="primary" align="left" style={{marginBottom: 30}}>Строительные конструкции</Text>
            <Text size="m" view="primary" spacing="m" align="left" style={{marginBottom: 15}}>
                <strong>Описание объекта контроля:</strong>
            </Text>
            <Text size="m" align="left" style={{marginBottom: 20}}>
                <strong>Строительные конструкции</strong>, включая здания, мосты и тоннели, являются основой инфраструктуры города и требуют высокого уровня надежности и безопасности. Контроль строительных конструкций необходим для обеспечения их прочности, долговечности и устойчивости к внешним воздействиям, таким как погодные условия и механические нагрузки.
            </Text>
            <Text size="m" view="primary" spacing="m" align="left" style={{marginBottom: 15}}>
                <strong>Методы контроля:</strong>
            </Text>
            <Text size="m" align="left" style={{marginBottom: 20}}>
            Для оценки состояния строительных конструкций применяются ультразвуковой контроль (УЗК) для выявления внутренних дефектов в материале, магнитный контроль (МК) для обнаружения поверхностных и подповерхностных дефектов в ферромагнитных элементах, и визуально-измерительный контроль (ВИК) для оценки внешнего состояния и геометрии конструкций. 
            </Text>
            <Text size="m" view="primary" spacing="m" align="left" style={{marginBottom: 15}}>
                <strong>Примеры работ и кейсы:</strong>
            </Text>
            <Text size="m" align="left" style={{marginBottom: 10}}>
                1. Ультразвуковая проверка бетонных стен здания на предмет пустот и трещин: идентификация потенциальных проблемных зон.
            </Text>
            <Text size="m" align="left" style={{marginBottom: 10}}>
                2. Магнитный контроль стальных балок моста: выявление трещин и оценка необходимости ремонтных работ.
            </Text>
            <Text size="m" align="left" style={{marginBottom: 20}}>
                3. Осмотр сварных швов стального моста: Выявление трещин и оценка качества сварки для планирования ремонтных работ.
            </Text>
            <Text size="m" view="primary" spacing="m" align="left" style={{marginBottom: 15}}>
                <strong>Особенности и рекомендации:</strong>
            </Text>
            <Text size="m" align="left" style={{marginBottom: 30}}>
            При контроле строительных конструкций крайне важно регулярно проводить комплексные инспекции, чтобы обеспечить их безопасность и функциональность на протяжении всего срока эксплуатации. Интеграция современных технологий и методов неразрушающего контроля позволяет эффективно идентифицировать потенциальные дефекты и планировать необходимые ремонтные работы.
            </Text>
        </div>
    );
}

export default ObjectSk;