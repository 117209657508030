import React from 'react';
import { IconBag } from '@consta/icons/IconBag';
import { IconPhone } from '@consta/icons/IconPhone';
import { IconAreaChart } from '@consta/icons/IconAreaChart';
import { IconToolsIncline } from '@consta/icons/IconToolsIncline';
import { IconTest } from '@consta/icons/IconTest';
import { IconDocFilled } from '@consta/icons/IconDocFilled';
import { IconThumbUp } from '@consta/icons/IconThumbUp';
import { IconRouble } from '@consta/icons/IconRouble';
import { Text } from '@consta/uikit/Text';
import { Card } from '@consta/uikit/Card';

interface ServiceItemProps {
    icon: React.ReactElement;
    title: string;
    description?: string;
  }

  const ServiceItem: React.FC<ServiceItemProps> = ({ icon, title, description }) => (
    <Card verticalSpace="m" horizontalSpace="s" style={{ width: 300, marginBottom: '20px' }}>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '3px' }}>
          {icon}
          <Text as="p" size="m" weight="bold" style={{ marginLeft: '3px' }}>{title}</Text>
        </div>
        {description && (
          <Text as="p" size="s" view="secondary">
            {description}
          </Text>
        )}
      </div>
    </Card>
  );
  
  const ServicesGrid: React.FC = () => {
    return (
    <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' , gap: 10}}>
      <ServiceItem icon={<IconPhone size="l" />} title="Оформите заявку" description="Можно в форме на сайте, по телефону +7 (922) 785-24-78 или на почту info@farra-lab.ru" />
      <ServiceItem icon={<IconDocFilled size="l" />} title="Спецификация требований" description="Уточняем требования и стоимость, подготавливаем спецификацию требований" />
      <ServiceItem icon={<IconBag size="l" />} title="Подписываем договор" description="Выбираем метод оплаты, договариваемся о прочих условиях" />
      <ServiceItem icon={<IconToolsIncline size="l" />} title="Полевые исследования" description="Полевое тестирование, включающее инструментальные измерения и сбор материалов" />
      <ServiceItem icon={<IconTest size="l" />} title="Лабораторные исследования" description="Проведение исследований отобранных образцов" />
      <ServiceItem icon={<IconAreaChart size="l" />} title="Формирование отчетов" description="Итоги полевых и лабораторных испытаний" />
      <ServiceItem icon={<IconThumbUp size="l" />} title="Отправляем отчет заказчику" description="Передаем заключение вместе Актом приема-передачи" />
      <ServiceItem icon={<IconRouble size="l" />} title="Итоговый расчет" description="После проверки отчета получаем оплату" />
    </div>
  );};
  
  export default ServicesGrid;