import React from 'react';
import { Text } from '@consta/uikit/Text'; 
import { ThemeToggler } from '@consta/uikit/ThemeToggler';
import { items, getItemIcon } from '../../config/themeConfig';
import { controlMethods, controlObjects } from '../types/ItemsLink';
import { Card } from '@consta/uikit/Card';
import Logo  from "../CompanyLogoAnamation/LogoPicture"
import { ContextMenuButton } from './ContextMenuButton';
import { MyChipsHeader } from '../Chips';
import SEOHelmet from '../../SEO/SEOHelmet';
import './header.css';


interface HeaderProps {
  currentTheme: string;
  setCurrentTheme: (themeKey: string) => void;
}

const Header: React.FC<HeaderProps> = ({ currentTheme, setCurrentTheme }) => {
  return (
    <header className="header">
      <SEOHelmet />
      <Card className="card-horizontal" verticalSpace="xs" horizontalSpace="xs" form="square">
        <Logo/>
        <Text>
          <span>ЛНК Farra Lab:<br />Ваш надежный партнер</span>
        </Text>
        <ContextMenuButton buttonLabel="Методы контроля" items={controlMethods} />
        <ContextMenuButton buttonLabel="Объекты контроля" items={controlObjects} />
        <MyChipsHeader  className="card-horizontal"/>
        <Text align = "left">
          <span>Контакты:<br />+7 (922) 785-24-78<br />info@farra-lab.ru</span>
        </Text>
        <ThemeToggler
          // Передача массива ключей тем в качестве items для ThemeToggler
          items={items.map(item => item.key)} // Изменено здесь
          value={currentTheme}
          getItemKey={(item) => item} // Ключ и есть строка, так что просто возвращаем item
          getItemLabel={(item) => items.find(themeItem => themeItem.key === item)?.label || ''} // Возвращаем label для найденного ключа
          getItemIcon={(item) => getItemIcon(item)} // Используем функцию getItemIcon для получения иконки
          onChange={(newTheme) => setCurrentTheme(newTheme)} // newTheme будет ключом выбранной темы
          direction="downStartLeft"
        />
      </Card>
    </header>
  );
};

export default Header;