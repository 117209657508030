import React from 'react';
import { Helmet } from 'react-helmet';

const SEOHelmet: React.FC = () => {
    return (
        <Helmet>
            <title>Farra-Lab - Лаборатория неразрушающего контроля</title>
            <meta name="description" content="Добро пожаловать в Farra-Lab, молодую и амбициозную лабораторию неразрушающего контроля. Предлагаем услуги ВИК, ГИ, УЗК, МК с использованием передовых технологий." />
        </Helmet>
    );
};

export default SEOHelmet;