import React, { useEffect } from 'react';
import { Text } from '@consta/uikit/Text';

const ObjectT = () => {
    useEffect(() => {
        const hash = window.location.hash;
        if (hash) {
          const element = document.querySelector(hash);
          if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
          }
        }
      }, []);
    return (
        <div id="object-section" className="custom-div">
            <Text size="2xl" weight="bold" view="primary" align="left" style={{marginBottom: 30}}>Трубопроводы</Text>
            <Text size="m" view="primary" spacing="m" align="left" style={{marginBottom: 15}}>
                <strong>Описание объекта контроля:</strong>
            </Text>
            <Text size="m" align="left" style={{marginBottom: 20}}>
                <strong>Трубопроводы -</strong> это критически важные элементы инфраструктуры, используемые для транспортировки жидкостей и газов в различных отраслях, включая нефтегазовую, химическую и коммунальную. От надежности трубопроводов зависит безопасность эксплуатации всей системы. Контроль трубопроводов направлен на обнаружение коррозии, трещин, износа и других дефектов, которые могут привести к утечкам и авариям.
            </Text>
            <Text size="m" view="primary" spacing="m" align="left" style={{marginBottom: 15}}>
                <strong>Методы контроля:</strong>
            </Text>
            <Text size="m" align="left" style={{marginBottom: 20}}>
                Для контроля трубопроводов используются различные методы неразрушающего контроля, такие как ультразвуковой контроль (УЗК), радиографический контроль (РГК), магнитный контроль (МК), и визуально-измерительный контроль (ВИК). В зависимости от спецификации трубопровода и условий его эксплуатации выбирают наиболее подходящий метод.
            </Text>
            <Text size="m" view="primary" spacing="m" align="left" style={{marginBottom: 15}}>
                <strong>Примеры работ и кейсы:</strong>
            </Text>
            <Text size="m" align="left" style={{marginBottom: 10}}>
                1. Ультразвуковое обследование трубопровода нефтеперерабатывающего завода: выявление зон с пониженной толщиной стенки из-за коррозии.
            </Text>
            <Text size="m" align="left" style={{marginBottom: 10}}>
                2. Гидравлическое испытание новых трубопроводов на нефтеперерабатывающем заводе: обнаружение утечек и подтверждение прочности конструкции
            </Text>
            <Text size="m" align="left" style={{marginBottom: 20}}>
                3. Магнитный контроль для обнаружения трещин на стальных трубопроводах в районе агрессивных сред.
            </Text>
            <Text size="m" view="primary" spacing="m" align="left" style={{marginBottom: 15}}>
                <strong>Особенности и рекомендации:</strong>
            </Text>
            <Text size="m" align="left" style={{marginBottom: 30}}>
                При контроле трубопроводов важно учитывать факторы, такие как тип транспортируемого вещества, давление в системе и климатические условия. Регулярный и систематический контроль помогает предотвратить аварийные ситуации и обеспечивает долгосрочную эксплуатацию трубопроводов.
            </Text>
        </div>
    );
}

export default ObjectT;