import React from 'react';
import { Picture } from '@consta/uikit/Picture';
import { Link } from 'react-router-dom';

const Logo = () => {
    return (
        <div>
          <Link to="/">
            {<Picture
            src={{
              'gpnDefault--1--1x': '/assets/images/logo_lnk.png',
              'gpnDark--1--1x': '/assets/images/logo_lnk_dark.png',
            }}
            alt="Лаборатория Неразрушающего Контроля"
            title="Лаборатория Неразрушающего Контроля"
            placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}  />}
          </Link>
        </div>
      );
}

export default Logo;