export type Item = {
    label: string;
    to: string; // Добавляем свойство для URL
    imageSrc: string;
  };

export  const controlMethods: Item[] = [
    {
      label: 'Ультразвуковой контроль (УЗК)',
      to: '/uzk#method-section',
      imageSrc: '/assets/images/UZK.webp'
    },
    {
      label: 'Гидравлическое испытание (ГИ)',
      to: '/gi#method-section' ,
      imageSrc: '/assets/images/GI.webp'
    },
    {
      label: 'Визуально-измерительный контроль (ВИК)',
      to: '/vik#method-section' ,
      imageSrc: '/assets/images/VIK.webp'
    },
    {
      label: 'Магнитный контроль (МК)',
      to: '/mk#method-section' ,
      imageSrc: '/assets/images/MK.webp'
    },
  ];
  
  export  const controlObjects: Item[] = [
    {
      label: 'Сварные соединения',
      to: '/svarnye-soedineniya#object-section' ,
      imageSrc: '/assets/images/object-control_ss.webp'
    },
    {
      label: 'Трубопроводы',
      to: '/truboprovody#object-section' ,
      imageSrc: '/assets/images/object-control_t.webp'
    },
    {
      label: 'Сосуды под давлением',
      to: '/sosudy-pod-davleniem#object-section' ,
      imageSrc: '/assets/images/object-control_spd.webp'
    },
    {
      label: 'Металлоконструкции',
      to: '/metalokonstrukciy#object-section' ,
      imageSrc: '/assets/images/object-control_m.webp'
    },
    {
      label: 'Литейные изделия',
      to: '/liteinie-izdeliya#object-section' ,
      imageSrc: '/assets/images/object-control_li.webp'
    },
    {
      label: 'Нефтегазовое оборудование',
      to: '/neftegazovoe-oborudovanie#object-section' ,
      imageSrc: '/assets/images/object-control_no.webp'
    },
    {
      label: 'Строительные конструкции',
      to: '/stroitelnie-konstrukciy#object-section' ,
      imageSrc: '/assets/images/object-control_sk.webp'
    },
  ];