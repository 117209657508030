import React, { useState } from 'react';
import { Picture } from '@consta/uikit/Picture';
import {  Modal } from '@consta/uikit/Modal';
import './about.css';

const Certificates: React.FC = () => {
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [selectedImage, setSelectedImage] = useState<string | null>(null);

    const handleImageClick = (imageSrc: string): void => {
        setSelectedImage(imageSrc);
        setIsModalOpen(true);
      };

  return (
    <div className="image-row">
      {/* Замените 'src' на путь к вашему изображению */}
      <Picture
        className="consta-picture"
        placeholder={undefined}
        src="/assets/images/certificates/attestat-1.webp"
        alt="Сертификат об аттестации лаборатории неразрушающего контроля"
        onClick={() => handleImageClick('/assets/images/certificates/attestat-1.webp')} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}      />
      <Picture
        className="consta-picture"
        placeholder={undefined}
        src="/assets/images/certificates/attestat-2.webp"
        alt="Сертификат об аттестации лаборатории неразрушающего контроля"
        onClick={() => handleImageClick('/assets/images/certificates/attestat-2.webp')} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}      />
      <Picture
        className="consta-picture"
        placeholder={undefined}
        src="/assets/images/certificates/attestat-3.webp"
        alt="Сертификат об аттестации лаборатории неразрушающего контроля"
        onClick={() => handleImageClick('/assets/images/certificates/attestat-3.webp')} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}      />
      <Picture
        className="consta-picture"
        placeholder={undefined}
        src="/assets/images/certificates/MK-1.webp"
        alt="Сертификат об аттестации лаборатории неразрушающего контроля"
        onClick={() => handleImageClick('/assets/images/certificates/MK-1.webp')} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}      />
      <Picture
        className="consta-picture"
        placeholder={undefined}
        src="/assets/images/certificates/MK-2.webp"
        alt="Сертификат об аттестации лаборатории неразрушающего контроля"
        onClick={() => handleImageClick('/assets/images/certificates/MK-2.webp')} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}      />
      <Picture
        className="consta-picture"
        placeholder={undefined}
        src="/assets/images/certificates/MK-3.webp"
        alt="Сертификат об аттестации лаборатории неразрушающего контроля"
        onClick={() => handleImageClick('/assets/images/certificates/MK-3.webp')} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}      />
      <Picture
        className="consta-picture"
        placeholder={undefined}
        src="/assets/images/certificates/PVK-1.webp"
        alt="Сертификат об аттестации лаборатории неразрушающего контроля"
        onClick={() => handleImageClick('/assets/images/certificates/PVK-1.webp')} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}      />
      <Picture
        className="consta-picture"
        placeholder={undefined}
        src="/assets/images/certificates/PVK-2.webp"
        alt="Сертификат об аттестации лаборатории неразрушающего контроля"
        onClick={() => handleImageClick('/assets/images/certificates/PVK-2.webp')} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}      />
      <Picture
        className="consta-picture"
        placeholder={undefined}
        src="/assets/images/certificates/PVK-3.webp"
        alt="Сертификат об аттестации лаборатории неразрушающего контроля"
        onClick={() => handleImageClick('/assets/images/certificates/PVK-3.webp')} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}      />
      {isModalOpen && (
        <Modal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          hasOverlay
          onClickOutside={() => setIsModalOpen(false)}
          onEsc={() => setIsModalOpen(false)}
        >
          <img src={selectedImage || ''} alt="Увеличенное изображение"  className="modal-image"/>
        </Modal>
      )}
    </div>
  );
};

export default Certificates;