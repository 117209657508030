import React, { useEffect } from 'react';
import { Text } from '@consta/uikit/Text';

const ObjectLi = () => {
    useEffect(() => {
        const hash = window.location.hash;
        if (hash) {
          const element = document.querySelector(hash);
          if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
          }
        }
      }, []);
    return (
        <div id="object-section" className="custom-div">
            <Text size="2xl" weight="bold" view="primary" align="left" style={{marginBottom: 30}}>Литейные изделия</Text>
            <Text size="m" view="primary" spacing="m" align="left" style={{marginBottom: 15}}>
                <strong>Описание объекта контроля:</strong>
            </Text>
            <Text size="m" align="left" style={{marginBottom: 20}}>
                <strong>Литейные изделия</strong> широко используются в автомобилестроении, машиностроении, строительстве и других отраслях промышленности. Эти изделия изготавливаются путем заливки расплавленного металла в формы, что может привести к образованию внутренних дефектов, таких как поры, трещины и включения. Контроль качества литейных изделий критичен для гарантии их прочности, долговечности и функциональности.
            </Text>
            <Text size="m" view="primary" spacing="m" align="left" style={{marginBottom: 15}}>
                <strong>Методы контроля:</strong>
            </Text>
            <Text size="m" align="left" style={{marginBottom: 20}}>
                Для проверки качества литейных изделий используется ультразвуковой контроль (УЗК), который позволяет обнаружить внутренние дефекты. Визуально-измерительный контроль (ВИК) применяется для оценки точности геометрии и внешнего состояния изделий. Магнитный контроль (МК) также используется для обнаружения поверхностных и подповерхностных дефектов в ферромагнитных материалах.
            </Text>
            <Text size="m" view="primary" spacing="m" align="left" style={{marginBottom: 15}}>
                <strong>Примеры работ и кейсы:</strong>
            </Text>
            <Text size="m" align="left" style={{marginBottom: 10}}>
                1. Ультразвуковой контроль стальных литейных изделий для строительной техники: подтверждение отсутствия дефектов в критических зонах.
            </Text>
            <Text size="m" align="left" style={{marginBottom: 10}}>
                2. Ультразвуковая проверка чугунных отливок для строительной арматуры: идентификация непроваров и внутренних трещин.
            </Text>
            <Text size="m" align="left" style={{marginBottom: 20}}>
                3. Магнитный контроль стальных литейных деталей для железнодорожного оборудования: выявление скрытых трещин и других дефектов.
            </Text>
            <Text size="m" view="primary" spacing="m" align="left" style={{marginBottom: 15}}>
                <strong>Особенности и рекомендации:</strong>
            </Text>
            <Text size="m" align="left" style={{marginBottom: 30}}>
                При контроле литейных изделий крайне важно учитывать специфику металла и технологию его обработки. Регулярные инспекции на всех этапах производства обеспечивают высокое качество конечного продукта, снижают риски отказов в эксплуатации и помогают поддерживать соответствие промышленным стандартам.
            </Text>
        </div>
    );
}

export default ObjectLi;