import React, { useState } from 'react';
import { Theme } from '@consta/uikit/Theme';
import { items, getTheme } from './config/themeConfig';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Slider from './components/Slider/Slider';
import Home from './pages/Home/Home';
import AboutCompany from './pages/About/About';
import MethodUzk from './pages/ControlMethods/ControlMethodUzk';
import MethodGi from './pages/ControlMethods/ControlMethodGi';
import MethodVik from './pages/ControlMethods/ControlMethodVik';
import MethodMk from './pages/ControlMethods/ControlMethodMk';
import ObjectSs from './pages/ControlObjects/ControlObjectSs';
import ObjectT from './pages/ControlObjects/ControlObjectT';
import ObjectSpd from './pages/ControlObjects/ControlObjectSpd';
import ObjectM from './pages/ControlObjects/ControlObjectM';
import ObjectLi from './pages/ControlObjects/ControlObjectLi';
import ObjectNo from './pages/ControlObjects/ControlObjectNo';
import ObjectSk from './pages/ControlObjects/ControlObjectSk';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css'

const App = () => {
  // Используется ключ темы для управления текущей темой
  const [currentTheme, setCurrentTheme] = useState(items[0].key); 
  return (
    <Theme preset={getTheme(currentTheme)} className="App" data-theme={currentTheme}>
      <Header key={currentTheme} currentTheme={currentTheme} setCurrentTheme={setCurrentTheme} />
      <Slider/>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-company" element={<AboutCompany />} />
        <Route path="/uzk" element={<MethodUzk />} />
        <Route path="/gi" element={<MethodGi />} />
        <Route path="/vik" element={<MethodVik />} />
        <Route path="/mk" element={<MethodMk />} />
        <Route path="/svarnye-soedineniya" element={<ObjectSs />} />
        <Route path="/truboprovody" element={<ObjectT />} />
        <Route path="/sosudy-pod-davleniem" element={<ObjectSpd />} />
        <Route path="/metalokonstrukciy" element={<ObjectM />} />
        <Route path="/liteinie-izdeliya" element={<ObjectLi />} />
        <Route path="/neftegazovoe-oborudovanie" element={<ObjectNo />} />
        <Route path="/stroitelnie-konstrukciy" element={<ObjectSk />} />
      </Routes>
      <Footer/>
    </Theme>
  );
};

export default App;
