import React, { useState, useRef } from 'react';
import { Button } from '@consta/uikit/Button';
import { ContextMenu } from '@consta/uikit/ContextMenu';
import {  useNavigate } from 'react-router-dom'; // Импортируйте Link из React Router
import { Item } from '../types/ItemsLink';
import './header.css';

interface ContextMenuButtonProps {
  buttonLabel: string;
  items: Item[];
}

export const ContextMenuButton: React.FC<ContextMenuButtonProps> = ({ buttonLabel, items }) => {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const navigate = useNavigate();

  // Создание элементов меню, которые являются ссылками
  const menuItems = items.map((item) => ({
    ...item,
    onClick: () =>{
      navigate(item.to);  // Переход по URL
      setIsOpen(false);   }// Закрытие меню // Используем navigate для перехода по URL
  }));

  return (
    <>
      <Button ref={ref} label={buttonLabel} onClick={() => setIsOpen(!isOpen)} />
      <ContextMenu
        className='dropdown-menu'
        isOpen={isOpen}
        items={menuItems}
        anchorRef={ref}
        onClickOutside={() => setIsOpen(false)}
        direction="downStartLeft"
        getItemLabel={(item) => item.label} 
      />
    </>
  );
};