import React, { useEffect } from 'react';
import { Text } from '@consta/uikit/Text';

const ObjectSpd = () => {
    useEffect(() => {
        const hash = window.location.hash;
        if (hash) {
          const element = document.querySelector(hash);
          if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
          }
        }
      }, []);
    return (
        <div id="object-section" className="custom-div">
            <Text size="2xl" weight="bold" view="primary" align="left" style={{marginBottom: 30}}>Сосуды под давлением</Text>
            <Text size="m" view="primary" spacing="m" align="left" style={{marginBottom: 15}}>
                <strong>Описание объекта контроля:</strong>
            </Text>
            <Text size="m" align="left" style={{marginBottom: 20}}>
                <strong>Сосуды под давлением -</strong> это закрытые контейнеры, предназначенные для хранения или транспортировки жидкостей и газов под давлением. Эти сосуды используются в широком спектре отраслей, включая химическую, нефтегазовую, пищевую и фармацевтическую промышленность. От их надежности зависит безопасность всего производственного процесса, поэтому контроль их состояния имеет критическое значение.
            </Text>
            <Text size="m" view="primary" spacing="m" align="left" style={{marginBottom: 15}}>
                <strong>Методы контроля:</strong>
            </Text>
            <Text size="m" align="left" style={{marginBottom: 20}}>
                Для обеспечения безопасности и надежности сосудов под давлением используются различные методы неразрушающего контроля, такие как ультразвуковой контроль (УЗК) для обнаружения внутренних дефектов, радиографический контроль (РГК) для проверки сварных швов, а также гидравлические испытания (ГИ) для проверки прочности и герметичности. Визуально-измерительный контроль также широко применяется для оценки состояния внешней поверхности сосудов.
            </Text>
            <Text size="m" view="primary" spacing="m" align="left" style={{marginBottom: 15}}>
                <strong>Примеры работ и кейсы:</strong>
            </Text>
            <Text size="m" align="left" style={{marginBottom: 10}}>
                1. Гидравлическое испытание нового реактора для химической промышленности: проверка на прочность и устойчивость к давлению.
            </Text>
            <Text size="m" align="left" style={{marginBottom: 10}}>
                2. Ультразвуковой контроль стенок баллонов для сжатого газа: выявление утонения и других дефектов стенок.
            </Text>
            <Text size="m" align="left" style={{marginBottom: 20}}>
                3. Гидравлическое испытание сосудов под давлением на химическом заводе: Проверка прочности и герметичности новых реакторов перед вводом в эксплуатацию для обеспечения безопасности производственных процессов.
            </Text>
            <Text size="m" view="primary" spacing="m" align="left" style={{marginBottom: 15}}>
                <strong>Особенности и рекомендации:</strong>
            </Text>
            <Text size="m" align="left" style={{marginBottom: 30}}>
                При контроле сосудов под давлением особое внимание следует уделить состоянию сварных швов и областям возможных механических повреждений. Регулярный контроль помогает не только обнаруживать дефекты на ранней стадии, но и планировать профилактическое обслуживание, что значительно увеличивает срок службы оборудования.
            </Text>
        </div>
    );
}

export default ObjectSpd;