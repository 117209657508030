import React, { useEffect } from 'react';
import { Text } from '@consta/uikit/Text';

const ObjectM = () => {
    useEffect(() => {
        const hash = window.location.hash;
        if (hash) {
          const element = document.querySelector(hash);
          if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
          }
        }
      }, []);
    return (
        <div id="object-section" className="custom-div">
            <Text size="2xl" weight="bold" view="primary" align="left" style={{marginBottom: 30}}>Металлоконструкции</Text>
            <Text size="m" view="primary" spacing="m" align="left" style={{marginBottom: 15}}>
                <strong>Описание объекта контроля:</strong>
            </Text>
            <Text size="m" align="left" style={{marginBottom: 20}}>
                <strong>Металлоконструкции</strong> являются основой для многих видов строительства, включая здания, мосты, и другие инфраструктурные объекты. Они должны выдерживать высокие нагрузки и атмосферные воздействия, что делает их контроль критически важным для обеспечения безопасности и долговечности конструкций.
            </Text>
            <Text size="m" view="primary" spacing="m" align="left" style={{marginBottom: 15}}>
                <strong>Методы контроля:</strong>
            </Text>
            <Text size="m" align="left" style={{marginBottom: 20}}>
                Контроль металлоконструкций осуществляется с использованием различных методов неразрушающего контроля, таких как ультразвуковой контроль (УЗК) для выявления внутренних дефектов, магнитный контроль (МК) для обнаружения поверхностных и подповерхностных трещин, а также визуально-измерительный контроль (ВИК) для оценки состояния и целостности видимых элементов. Радиографический контроль (РГК) также может использоваться для детальной проверки сварных соединений.
            </Text>
            <Text size="m" view="primary" spacing="m" align="left" style={{marginBottom: 15}}>
                <strong>Примеры работ и кейсы:</strong>
            </Text>
            <Text size="m" align="left" style={{marginBottom: 10}}>
                1. Ультразвуковая дефектоскопия сварных швов металлического каркаса нового торгового центра: обеспечение отсутствия дефектов в критически важных соединениях.
            </Text>
            <Text size="m" align="left" style={{marginBottom: 10}}>
                2. Магнитный контроль опорных балок моста: выявление трещин и оценка необходимости ремонтных работ.
            </Text>
            <Text size="m" align="left" style={{marginBottom: 20}}>
                3. Визуальный осмотр и измерение толщины защитного покрытия металлоконструкций спортивной арены: предотвращение коррозии и продление срока службы конструкции.
            </Text>
            <Text size="m" view="primary" spacing="m" align="left" style={{marginBottom: 15}}>
                <strong>Особенности и рекомендации:</strong>
            </Text>
            <Text size="m" align="left" style={{marginBottom: 30}}>
            При планировании контроля металлоконструкций важно учитывать условия эксплуатации объекта, такие как климатические особенности, тип и интенсивность нагрузок. Регулярный и всесторонний контроль позволяет не только обнаруживать дефекты на ранних стадиях, но и эффективно планировать техническое обслуживание и ремонт, что значительно увеличивает безопасность и эксплуатационные характеристики конструкций.
            </Text>
        </div>
    );
}

export default ObjectM;