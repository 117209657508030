import React from 'react';
import { Text } from '@consta/uikit/Text'; 
import { Grid } from '@consta/uikit/Grid'; 
import { useNavigate } from 'react-router-dom';
import { controlMethods, controlObjects, Item } from '../types/ItemsLink';
import { Card } from '@consta/uikit/Card';
import Logo from "../CompanyLogoAnamation/LogoPicture"
import { MyChipsHeader } from '../Chips';
import './footer.css';

const Footer: React.FC = () => {
    const navigate = useNavigate();
    const handleNavigate = (path: string) => {
        navigate(path);
    };
    const currentYear = new Date().getFullYear(); // Получаем текущий год

    return (
        <footer className = 'footer'>
            <Card className="card-horizontal" verticalSpace="xs" horizontalSpace="xs" form="square">
                <Logo/>
                <div className ="text-block">
                    <Text size="m" align="left" style={{ marginTop: '20px' }}>
                        <strong>Контакты:</strong><br />+7 (922) 785-24-78
                    </Text>
                    <Text size="m" align="left" style={{ marginTop: '20px' }}>
                    +7 (951) 973-16-56<br />+7 (904) 467-07-98
                    </Text>
                    <Text size="m" align="left" style={{ marginTop: '20px' }}>
                        <strong>Юр. имя:</strong><br />ИП Фаррахов Рустам Раисович
                    </Text>
                    <Text size="m" align="left" style={{ marginTop: '20px' }}>
                        <strong>E-mail:</strong><br />info@farra-lab.ru
                    </Text>
                </div>
                <div className ="text-block">
                    <Text size="m" align="left" style={{marginTop: '20px'}}>
                        <strong>Юридический адрес:</strong><br />628680 ХМАО, г. Мегион, ул. Нефтяников д.5 кв.3<br />
                    </Text>
                    <Text size="m" align="left" style={{marginTop: '20px'}}>
                        <strong>Фактический адрес:</strong> <br />450092б г. Уфа, ул. Рабкоров д.10 кв.48
                    </Text>
                    <Text size="m" align="left" style={{marginTop: '20px'}}>
                        <strong>ИНН:</strong> <br />027104023400
                    </Text>
                    <Text size="m" align="left" style={{marginTop: '20px'}}>
                        <strong>ОГРНИП:</strong> <br />322861700066274
                    </Text>
                </div>
                <Grid className="grid-vertical" gap="m" cols={2} style={{ padding: '20px' }} >
                    <div>
                        <Grid gap="s" cols={1} xAlign="left">
                            <Text size="xl" view="primary">Методы контроля</Text>
                            {controlMethods.map((item: Item, index: number) => (
                                <Text view="link" key={index} size="m" onClick={() => handleNavigate(item.to)} style={{ cursor: 'pointer' }}>
                                    {item.label}
                                </Text>
                            ))}
                        </Grid>
                    </div>
                    <div>
                        <Grid gap="s" cols={1} xAlign="left">
                            <Text size="xl" view="primary">Объекты контроля</Text>
                            {controlObjects.map((item: Item, index: number) => (
                                <Text view="link" key={index} size="m" onClick={() => handleNavigate(item.to)} style={{ cursor: 'pointer' }}>
                                    {item.label}
                                </Text>
                            ))}
                        </Grid>
                    </div>
                </Grid>
                <Text size="m" className='center-text' style={{ marginTop: '20px', alignSelf: 'center' }}>
                    © {currentYear} ИП Фаррахов Р.Р. (Farra Lab). Все права защищены.
                </Text>
            </Card>
        </footer>
    );
}

export default Footer;