import React, { useEffect } from 'react';
import ImageBlock from './ImageBlock';
import { controlMethods } from '../../components/types/ItemsLink'; 
import { controlObjects } from '../../components/types/ItemsLink';
import { Text } from '@consta/uikit/Text';
import ServicesGrid from './ServicesGrid';
import './home.css';

const Home: React.FC = () => {
    useEffect(() => {
        // Скроллим вверх при монтировании компонента
        window.scrollTo({
          top: 0, // Указываем верхнюю координату скролла
          behavior: 'smooth' // Делаем скроллинг плавным
        });
      }, []);
    return (
      <>
        <div>
            <Text as="h1" size="xl" weight="bold" view="primary" align="center" spacing="xs" style={{marginTop: 50}}>
                Мы проводим следующие виды контроля:
            </Text>
            <Text as="p" size="l" view="secondary" align="center">
                Выберите один из методов контроля, чтобы узнать больше информации о каждом из них.
            </Text>
            <div className="image-grid">
                {controlMethods.map((method, index) => (
                    <ImageBlock key={index} item={method} />
                ))}
            </div>
        </div>
        <div>
            <Text as="h1" size="xl" weight="bold" view="primary" align="center" spacing="xs" style={{marginTop: 50}}>
                Работаем с объектами разных сфер применения:
            </Text>
            <Text as="p" size="l" view="secondary" align="center">
                Выберите один из объектов, чтобы узнать больше информации о каждом из них.
            </Text>
            <div className="image-grid">
                {controlObjects.map((method, index) => (
                    <ImageBlock key={index} item={method} />
                ))}
            </div>            
        </div>
        <div>
            <Text as="h1" size="xl" weight="bold" view="primary" align="center" spacing="xs" style={{marginTop: 50}}>
                Порядок работы с нами
            </Text>
            <ServicesGrid/>
        </div>
       </>
    );
  };
  
  export default Home;